.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../images/wardrobe.jpg");
  height: 100vh;
}

.login-wrapper {
  width: 250px;

  background-color: aliceblue;
  padding: 50px;
  border-radius: 25px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.login-text {
  font-family: "Pacifico";
  font-size: 35px;
}

.input-slot {
  height: 25px;
  width: 200px;
  margin: 3px;
  border-radius: 7px;
  border-color: azure;
}

.input-slot::placeholder {
  outline: hidden;
  padding: 3px;
}

.login-button {
  color: white;
  width: 150px;
  height: 50px;
  border: none;
  background-color: #5ab1b8;
  border-radius: 25px;
  font-size: medium;
  font-family: "poppins";
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.login-button:hover {
  opacity: 3;
  cursor: pointer;
}

.logo-img {
  background-image: url("../images/playstore.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 100px;
  height: 100px;
}

.error-message1 {
  font-size: 13px;
  font-family: "poppins";
  color: coral;
  margin-bottom: 30px;
}

.error-message2 {
  font-size: 13px;
  font-family: "poppins";
  color: coral;
}

.error-message {
  font-size: 13px;
  font-family: "poppins";
  color: coral;
}
