.delete-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #5ab1b8;
}

.delete-wrapper {
  width: 250px;

  background-color: aliceblue;
  padding: 50px;
  border-radius: 25px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.bin-svg {
  background-image: url("../images/playstore.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 100px;
  height: 100px;
}

.confirmation-text {
  font: "poppins";
  font-weight: 600;
  font-size: 20px;
}

.delete-button {
  width: 150px;
  height: 50px;
  margin-bottom: 7px;
  border-radius: 25px;
  border: none;
  background-color: red;
  font-family: "poppins";
  color: white;
}

.cancel-button {
  width: 150px;
  height: 50px;
  margin-bottom: 7px;
  border-radius: 25px;
  border: none;
  background-color: white;
  font-family: "poppins";
  color: #5ab1b8;
}

.delete-button:hover {
  cursor: pointer;
}

.cancel-button:hover {
  cursor: pointer;
}

.delete-msg {
  font-family: "poppins";
  font-weight: 200;
  color: grey;
  font-size: 13px;
}
